<template>
  <v-row justify="center">
    <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
    persistent
    >

      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Mantenedor de Parametros</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn dark text @click="dialog = false">Salir</v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="6" sm="8" md="8">
                <v-text-field
                label="Parametro"
                v-model="parametronombre"
                ></v-text-field>
              </v-col>
              <v-col
              cols="6"
              sm="4"
              md="4"
              >
                    <v-select
                    :items="nivelParametros"
                    label="Nivel"
                    v-model="nivelSelect"
                    append-outer-icon="mdi-content-save"
                    @click:append-outer="guardaparametro()"
                    item-text="nombre"
                    item-value="id"
                    ></v-select>
              </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-card>
                        <v-card-title>
                        <v-text-field
                            v-model="search"
                            append-icon="mdi-magnify"
                            label="Search"
                            single-line
                            hide-details
                        ></v-text-field>
                        </v-card-title>
                        <v-data-table
                        dense
                        :headers="headers"
                        :items="data_ensayo.parametros"
                        :search="search"
                        class="elevation-1"
                        >
                        <template v-slot:[`item.nombre`]="{ item }">
                          <v-text-field
                          label="Nombre"
                          outlined
                          v-model="item.nombre"
                          :disabled="!item.active"
                          ></v-text-field>
                        </template>

                        <template v-slot:[`item.especie`]="{ item }">
                            <v-select
                            outlined
                            :items="nivelParametros"
                            :disabled="!item.active"
                            label="Nivel"
                            item-text="nombre"
                            item-value="id"
                            v-model="item.especie"
                            @change="cambiarNivel(item)"
                            ></v-select>
                        </template>

                        </v-data-table>
                    </v-card>
                </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import Service from '../../services/apis';

export default {
  data: () => ({
    dialog: false,
    search: '',
    parametronombre: '',
    nivelSelect: null,
    nivelParametros: [
      {
        id: 1,
        nombre: 'Patogeno alto',
        nivel: 1,
      },
      {
        id: 2,
        nombre: 'Patogeno bajo',
        nivel: 2,
      },
      {
        id: 3,
        nombre: 'Indicador',
        nivel: 3,
      },
    ],
    headers: [
      {
        text: 'Parametro',
        align: 'start',
        value: 'nombre',
      },
      { text: 'Nivel', value: 'especie' },
      // { text: 'Conf.', class: 'td-btn', value: 'cod_pat' },
    ],
  }),
  computed: {
    ...mapState('Ensayos', ['form_data', 'obj_matrices', 'data_ensayo']),
  },
  methods: {
    ...mapMutations('Ensayos', ['setMix']),
    openmodal() {
      this.dialog = true;
    },
    async guardaparametro() {
      if (this.parametronombre !== '') {
        const dateact = new Date();
        const seconds = dateact.getTime();
        const crearParam = {
          nombre: this.parametronombre,
          nomcort: `${this.parametronombre}_${seconds}`,
          clasificacion: 2,
          especie: this.nivelSelect,
          unidad: null,
          color: '#795548',
          nombre_es: this.parametronombre,
        };
        const creaparam = await Service.apiToken('POST', 'crea-parametro', this.$ls.storage.token, crearParam);
        this.data_ensayo.parametros.push(creaparam.data);
        this.parametronombre = '';
        this.nivelSelect = null;
      }
    },
  },
};
</script>
